import React, { useEffect, useState } from 'react';
import axios from 'axios';

const DorpskerkWezep = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUrlAndRedirect = async () => {
      try {
        const username = 'dorpskerk';
        const response = await axios.get(`https://youtube.locomediagroep.nl/api/get-url-for-user?username=${username}`);
        // const url = response.data.content;
        const videotag = response.data.content.replace('https://www.youtube.com/watch?v=','');
        const url = `https://www.youtube.com/embed/${videotag}?autoplay=1&controls=0&disablekb=1&enablejsapi=1&iv_load_policy=3`
        window.location.href = url;
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchUrlAndRedirect();
  }, []);

  return (
    <div>
      {loading ? <h1>Redirecting...</h1> : <h1>Error loading URL. Please try again.</h1>}
    </div>
  );
};

export default DorpskerkWezep;
