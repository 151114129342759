import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom';
import './App.css'; // Import the CSS file
import DorpskerkWezep from './DorpskerkWezep';
import VredeskerkWezep from './VredeskerkWezep';
import logo from './logo.png'; 

const handleLogout = () => {
  localStorage.removeItem('token');
  window.location.href = '/';
};

const App = () => {
  const token = localStorage.getItem('token');
  const isLoggedIn = !!token;
  const username = isLoggedIn ? JSON.parse(atob(token.split('.')[1])).username : '';

  return (
    <Router>
      <div>
        <nav className="top-bar">
          <img src={logo} alt="Logo" className="logo" />
          {isLoggedIn && (
            <div className="user-info">
              <p className="username">Gebruiker: {username}</p>
              <button className="logout" onClick={handleLogout}>
                Logout
              </button>
            </div>
          )}
        </nav>
        <div className="app">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/urlAanpassen" element={<Form />} />
            <Route path="/DorpskerkWezep" element={<DorpskerkWezep />} />
            <Route path="/VredeskerkWezep" element={<VredeskerkWezep />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const token = localStorage.getItem('token');
  const isLoggedIn = !!token;
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/urlAanpassen');
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://youtube.locomediagroep.nl/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      localStorage.setItem('token', data.token);
      window.location.href = '/urlAanpassen';
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login">
      <h1>Login</h1>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

const Form = () => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentValue = async () => {
      try {
        const response = await fetch('https://youtube.locomediagroep.nl/api/get-text', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error);
        }

        setInputValue(data.content);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentValue();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://youtube.locomediagroep.nl/api/save-text', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ content: inputValue }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      setError('');
      alert('Text saved successfully');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="form">
          <h1>YouTube URL aanleveren</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {error && <p className="error">{error}</p>}
          <form onSubmit={handleSubmit}>
            <label>Voer hier onder YouTube URL in die uitgezonden moet worden:</label>
            <input
              type="text"
              placeholder={inputValue || 'Enter your text'}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button type="submit">Opslaan</button>
          </form>
        </>
      )}
    </div>
  );
};


export default App;
